@tailwind base;
@tailwind components;
@tailwind utilities;

  /* === Code editor === */
  code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	background: none;
	text-shadow: 0 1px rgba(0, 0, 0, 0.3);
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
	padding: 1em;
	margin: .5em 0;
	overflow: auto;
	border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #282a36;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
	padding: .1em;
	border-radius: .3em;
	white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #6272a4;
}

.token.punctuation {
	color: #f8f8f2;
}

.namespace {
	opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #ff79c6;
}

.token.boolean,
.token.number {
	color: #bd93f9;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #50fa7b;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
	color: #f1fa8c;
}

.token.keyword {
	color: #8be9fd;
}

.token.regex,
.token.important {
	color: #ffb86c;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}
/* === Code editor === */

*:not(textarea)::selection {
	background-color: #fe83e6;
	color: black !important;
  }

.gradient-text {
background-image: linear-gradient(to bottom, #fff, rgba(255,255,255,0.7));
-webkit-background-clip: text;
color: transparent;
}

.hero-radial-background {
background-image: radial-gradient(ellipse at top, rgba(187,67,252,0.15), #1E1E1E);
}

.cobe-backdrop {
	backdrop-filter: blur(10px);
}

.glow {
	height: 100%;
}

.use-case-background {
	/* background-image: url('/use-case-background.svg');
	background-repeat: no-repeat; */
	background-image: radial-gradient(ellipse at bottom, rgba(187,67,252,0.05), #1E1E1E);
}

#features {
	background: linear-gradient(180deg, #1E1E1E 0%, #1E1E1E 56%, rgba(30, 30, 30, 0.62) 38%, rgba(30, 30, 30, 0.00) 100%);

}

.feature-background {
	/* background-image: url('/use-case-background-180.svg');
	background-position: 0% 185%; */
}


html {
	background-color: #1E1E1E;
}

.landing-page-diamond-gradient {
	background: linear-gradient(to bottom right, rgba(236, 56, 228, 0.14) 0%, rgba(114, 48, 254, 0.12) 18%, rgba(34, 34, 34, 0.40) 33%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, rgba(236, 56, 228, 0.14) 0%, rgba(114, 48, 254, 0.12) 18%, rgba(34, 34, 34, 0.40) 33%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, rgba(236, 56, 228, 0.14) 0%, rgba(114, 48, 254, 0.12) 18%, rgba(34, 34, 34, 0.40) 33%) top left / 50% 50% no-repeat, linear-gradient(to top right, rgba(236, 56, 228, 0.14) 0%, rgba(114, 48, 254, 0.12) 18%, rgba(34, 34, 34, 0.40) 33%) top right / 50% 50% no-repeat;
}